<template>
    <div>
        <LoaderDefault v-if="isLoading" />

        <div v-else>
            <DefaultHeader2 :routeBack="'/taips'" :titleHeader="'lista Documentos Taip'" />
            <a-row :gutter="24" type="flex">
                <a-col :span="24" class="mb-24">
                    <section class="data-top">
                        <div class="card-top" v-for="(item, index) in dataTaip.data" :key="index">
                            <p @click="actionFilterData(item, index)">{{ item.subtitle }}</p>
                        </div>
                    </section>
                    <div class="div-table-container">
                        <div class="card-header-table">
                            <div class="bg-gradient-primary">
                                <h5 class="title-table">Listado de documentos</h5>
                            </div>
                        </div>
                        <table class="table">
                            <thead class="tr-thead">
                                <tr class="tr-columns-data">
                                    <th class="th-colum-item">Nombre</th>
                                    <th class="th-colum-item">
                                        Tipo documento
                                    </th>
                                </tr>

                                <tr class="tr-columns-actions">
                                    <th class="th-actions">Acciones</th>
                                </tr>
                            </thead>
                            <tbody class="tbody-container">
                                <tr class="tr-body" v-for="(item, index) in this.listDocumentsInner" :key="index">
                                    <div class="tr-columns-data-body">
                                        <td class="th-colum-item">{{ item.title }}</td>
                                    </div>
                                    <br>
                                    <div class="tr-columns-actions">
                                        <td class="td-container-action td-success">
                                            <i class="el-icon-success icon-action" @click="openPdf(item.urlPdf)"></i>
                                        </td>
                                        <td class="td-container-action td-warning" @click="showFormEdit(item, index)">
                                            <i class="el-icon-edit icon-action"></i>
                                        </td>
                                        <td class="td-container-action td-danger" @click="showConfirmation(index)">
                                            <i class="el-icon-delete-solid icon-action"></i>
                                        </td>
                                    </div>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <section class="container-form-edit" id="container-form-edit"><span class="close-btn"
                            @click="closeFormEdi()"><a href="#">X</a></span>
                        <div class="show">
                            <h2 class="title-update">Actualizar documento </h2>
                            <div class="content-form mt-4">
                                <div class="content-inputs2 me-4" id="content-inputs">
                                    <label class="label" id="label" for="name">Nombre *</label>
                                    <input id="name" v-model="newDataDocument.title" class="input input-anexo" name="name"
                                        type="text" placeholder="Nombre" required>
                                </div>
                            </div>

                            <div class="mt-2">
                                <h5>¿Desea cambiar el documento asociado</h5>
                                <div>
                                    <div class="roundedTwo">
                                        <label>
                                            <input type="radio" name="opcion" value="si" @change="selectedOption = 'si'"> Sí
                                        </label>

                                        <label>
                                            <input type="radio" name="opcion" value="no" checked
                                                @change="selectedOption = 'no'"> No
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="divselect" v-if="selectedOption === 'si'">
                                <div class="div-select-custom">
                                    <input id="file-5" type="file" @click="loadFilePdf" @change="loadFilePdf()"
                                        name="file-5" class="inputfile inputfile-5 input-anexo" required />
                                    <label id="label" for="file-5" class="label-file label">
                                        <figure>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="iborrainputfile" width="20"
                                                height="17" viewBox="0 0 20 17">
                                                <path
                                                    d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z">
                                                </path>
                                            </svg>
                                        </figure>
                                    </label>
                                </div>
                                <h6 class="name-pdf-selected label" id="name-pdf-selected">Seleccionar nuevo archivo *</h6>
                            </div>
                            <button class="btn-save-data mt-4" role="button"
                                @click="validateFomrEditDocument()">Actualizar
                                Documento</button>
                        </div>
                    </section>
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<script>
import DefaultHeader2 from '../../../components/Headers/DefaultHeader2.vue'
import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';
import { db, storage } from '../../../../firebaseConfig';

export default {

    components: {
        DefaultHeader2,
        LoaderDefault
    },
    data() {
        return {
            idParam: String,

            listDocumentsInner: [],
            listDataDocumentsUpdate: [],
            indexOne: 0,
            indexTwo: 0,

            dataTaip: [],
            idParam: '',
            isLoading: false,
            paginate: ['listDocumentsInne'],
            selectedOption: 'no',
            selectedFile: File,

            newDataDocument: {
                _idTitle: "_id",
                title: "",
                urlPdf: ""
            }

        }
    },
    mounted() {
        this.idParam = this.$route.params._id
        this.getInfoDetailTaip()
    },

    methods: {
        async getInfoDetailTaip() {
            this.isLoading = true
            const dataTaip = await db.collection('taips').doc(this.idParam).get()
            this.isLoading = false
            this.dataTaip = dataTaip.data()
        },

        actionFilterData(item, index) {
            this.listDocumentsInner = item.data
            this.listDataDocumentsUpdate = item
            this.indexOne = index
        },
        openPdf(urlPdf) { window.open(urlPdf, '_blank'); },

        showConfirmation(indexTwo) {
            this.indexTwo = indexTwo

            this.$swal
                .fire({
                    title: 'Eliminar Documento',
                    text: '¿Estás seguro de que deseas realizar esta acción? ' + this.listDataDocumentsUpdate.data[indexTwo].title,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: "#45A825",
                    cancelButtonText: 'Cancelar',
                })
                .then((result) => {
                    if (result.value) this.deleteFileOfDocument(indexTwo, true);
                });
        },

        async deleteFileOfDocument(indexTwo, deleteFirestore) {
            this.isLoading = true
            (this.listDataDocumentsUpdate.data[indexTwo]._idTitle);

            const storageRef = storage.ref()
            const pdfRef = storageRef.child('pdfsDocumentsTaips')
                .child(this.idParam)
                .child(this.listDataDocumentsUpdate._idSubtitle)
                .child(this.listDataDocumentsUpdate.data[indexTwo]._idTitle)



            try {
                if (deleteFirestore) {
                    await this.removeDocumentFirestoreDataTwo(indexTwo)
                }
                await pdfRef.delete()
                await this.getInfoDetailTaip()
            } catch (error) {
                this.isLoading = false
            }
        },


        showFormEdit(item, indexTwo) {
            this.indexTwo = indexTwo
            this.newDataDocument = item
            const layoutForm = document.getElementById('container-form-edit')            
            layoutForm?.classList.remove('container-form-edit')
            layoutForm?.classList.add('container-form-edit-visible')
        },

        closeFormEdi() {
            const layoutForm = document.getElementById('container-form-edit')
            layoutForm.classList.add('container-form-edit')
            layoutForm.classList.remove('container-form-edit-visible')
        },

        loadFilePdf() {
            const input = document.getElementById('file-5')
            input.accept = "application/pdf";
            input?.addEventListener('change', (e) => {
                const target = e.target
                if (target.files && target.files.length > 0) {
                    const nameSelected = document.getElementById('name-pdf-selected');
                    nameSelected.innerHTML = '' + target.files[0].name;
                    this.selectedFile = target.files[0]
                }
            });
        },

        validateFomrEditDocument() {

            if (this.newDataDocument.title != '') {
                if (this.selectedOption === 'no') {
                    this.updateDocumentFirestoreDataTwo(false)
                } else {
                    this.updateDocumentFirestoreDataTwo(true)
                }
            } else {
                this.$swal
                    .fire({
                        title: 'Información importante',
                        text: 'Tenga en cuenta que los datos no deben estar vacion para poder actualizar el documento',
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#45A825",
                        cancelButtonText: 'Cancelar',
                    })
            }
        },

        async updateDocumentFirestoreDataTwo(changeFile) {
            this.isLoading = true
            this.closeFormEdi()
            const docRef = db.collection('taips').doc(this.idParam);
            const doc = await docRef.get();
            const data = doc.data().data;
            const nestedData = data[this.indexOne].data;
            nestedData[this.indexTwo] = this.newDataDocument;
            if (changeFile) {
                if (this.selectedFile instanceof File && this.selectedFile.size > 0) {
                    await this.deleteFileOfDocument(this.indexTwo, false)
                    const storageRef = storage.ref()
                    const pdfNewRef = storageRef
                        .child('pdfsDocumentsTaips')
                        .child(this.idParam)
                        .child(this.listDataDocumentsUpdate._idSubtitle)
                        .child(this.newDataDocument.title)
                    await pdfNewRef.put(this.selectedFile);
                    const pdfUrl = await pdfNewRef.getDownloadURL();
                    this.newDataDocument._idTitle = this.newDataDocument.title
                    this.newDataDocument.urlPdf = pdfUrl
                    nestedData[this.indexTwo] = this.newDataDocument;

                    await docRef.update({
                        "data": data
                    });
                    this.newDataDocument = {}
                    this.isLoading = false
                    this.$swal
                        .fire({
                            title: 'Acción exitosa',
                            text: 'Se ha actualizado el documento exitosamente',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Aceptar',
                            confirmButtonColor: "#45A825",
                        })
                    await this.getInfoDetailTaip()
                } else {
                    this.$swal
                        .fire({
                            title: 'Información importante',
                            text: 'Tenga en cuenta que los datos no deben estar vacion para poder actualizar el documento',
                            icon: 'warning',
                            showCancelButton: false,
                            confirmButtonText: 'Aceptar',
                            confirmButtonColor: "#45A825",
                        })
                    this.isLoading = false
                }
            } else {
                await docRef.update({
                    "data": data
                });
                this.newDataDocument = {}
                this.isLoading = false
                this.$swal
                    .fire({
                        title: 'Acción exitosa',
                        text: 'Se ha actualizado el documento exitosamente',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#45A825",
                    })
                this.closeFormEdi()
                await this.getInfoDetailTaip()
            }
        },


        async removeDocumentFirestoreDataTwo(indexTwo) {
            const docRef = db.collection('taips').doc(this.idParam);
            const doc = await docRef.get();
            const data = doc.data().data;
            const nestedData = data[this.indexOne].data;
            nestedData.splice(indexTwo, 1);
            await docRef.update({
                "data": data
            });
            await this.getInfoDetailTaip()
        }
    },
}
</script>